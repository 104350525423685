import {
  ELSToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import React, { ReactNode } from 'react';
import {
  isNil,
  orderBy,
  uniq
} from 'lodash';
import {
  ChatDto,
  ChatEntryAuthorDto,
  ChatEntryDto,
  ChatEntryTypeDto,
  ChatModelNameDto,
  ChatReferenceClassDto,
  ChatReferenceDto,
  ChatResourceTypeDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import {
  getFeatureFlagGroups,
  getGroupFeatureFlagWithFallbackToGlobal
} from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  EvolveProductDto,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { apStyleTitleCase } from '../../utilities/title-case.utilities';
import {
  TEMP_BOT_CHAT_ID_STREAM,
  TEMP_BOT_CHAT_ID_SYNC
} from './ai-chat.constants';

export const getCitations = (entry: ChatEntryDto): ChatReferenceDto[] => {
  if (!entry || !entry.references || !entry.references.length) {
    return null;
  }

  return entry.references.filter((reference) => {
    return reference.isVisible
      && reference.referenceClass === ChatReferenceClassDto.CITATION;
  }) as ChatReferenceDto[];
};

export const getOsmosisVideoId = (reference: Partial<ChatReferenceDto>) => {
  if (!reference || !reference.resourceUri) {
    return null;
  }
  const uriPrefix = 'https://osmosis.org/learn/';
  if (!reference.resourceUri.includes(uriPrefix)) {
    return null;
  }
  return reference.resourceUri.replace(uriPrefix, '');
};
export const getRecommendations = (entry: ChatEntryDto): ChatReferenceDto[] => {
  if (!entry || !entry.references || !entry.references.length) {
    return null;
  }

  return entry.references.filter((reference) => {
    const videoId = getOsmosisVideoId(reference);

    return reference.isVisible
      && reference.referenceClass === ChatReferenceClassDto.RECOMMENDATION
      && reference.resourceType === ChatResourceTypeDto.VIDEO
      && videoId;
  }) as ChatReferenceDto[];
};

export const toastApiError = (toastService: ELSToastService) => {

  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        <p>Request Error</p>
        <p>Please refresh the page and try again. If the problem persists please contact your administrator.</p>
      </div>
    ),
    type: ELSWithToastService.types.NEGATIVE
  });
};

export const toastActiveStreamingError = (
  toastService: ELSToastService,
  trackAction: (props: AnalyticsActionProps) => void,
  actionRef: string
) => {

  trackAction({
    action: AnalyticsAction.AI_CHAT_ACTION_WHILE_STREAMING_ERROR,
    props: {
      actionRef
    }
  });

  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        <p>This action cannot be performed while the current prompt is being answered</p>
      </div>
    ),
    type: ELSWithToastService.types.NEGATIVE
  });
};

export const getEbookCitationPaths = (citation: ChatReferenceDto): string[] => {
  if (citation.resourceType !== ChatResourceTypeDto.EBOOK) {
    return [];
  }
  if (!citation || !citation.uri) {
    return [];
  }
  const chatperSeperator = 'chapter:';
  const paths = citation.uri.split(chatperSeperator).reduce((acc, cur) => {
    if (!cur) {
      return acc;
    }
    return [
      ...acc,
      `${chatperSeperator}${cur}`
    ];
  }, []);
  return paths.map((path) => {
    return path.split('|').filter((part) => {
      return !!part;
    }).map((part) => {
      return apStyleTitleCase(part.toLowerCase(), null);
    }).map((part) => {
      return part
        .replace('Chapter:', 'Chapter: ')
        .replace('Section:', 'Section: ')
        .replace('Subsection:', 'Subsection: ')
        .replace('Step:', 'Step: ');
    })
      .join(' | ');
  });
};
export const getCitationDisplay = (citation: ChatReferenceDto): ReactNode => {
  if (citation.resourceType === ChatResourceTypeDto.EBOOK) {
    const paths = getEbookCitationPaths(citation);
    return (
      <div>
        <div>{citation.resourceTitle}</div>
        <div>
          {paths && paths.length > 0 && paths.map((path) => {
            return (
              <div
                key={path}
              >
                {path}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return citation.title;
};

export const getIsDirectAccess = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string,
  isbns: string[],
): boolean => {

  if (!featureFlagsGrouped || !courseSectionId || !isbns || !isbns.length) {
    return false;
  }

  const featureIsbns = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_DIRECT_CHATBOT_ISBN);

  if (!featureIsbns || !featureIsbns.length) {
    return false;
  }

  return featureIsbns.some((isbn) => {
    return isbns.some((_isbn) => {
      return _isbn === isbn;
    });
  });
};

export const extractNumber = (str: string): number => {
  const matches = str.match(/\((\d+)\)[^()]*$/);
  return matches ? parseInt(matches[1], 10) : null;
};
export const getNextChatTitle = (title: string, chats: ChatDto[]): string => {
  if (!chats || !chats.length || !title) {
    return title;
  }

  const numbers = chats.filter((chat) => {
    return chat.title && chat.title.startsWith(title);
  }).reduce((acc, chat) => {
    const number = extractNumber(chat.title);
    return [
      ...acc,
      isNil(number) ? 0 : number
    ];
  }, []).sort((a, b) => {
    if (isNil(a) || isNil(b)) {
      return 0;
    }
    return a - b;
  }).reverse();

  if (!numbers || !numbers.length) {
    return title;
  }

  return `${title} (${numbers[0] + 1})`;
};

export const getLLMModel = (featureFlagsGrouped: FeatureFlagsGroupedDto[], courseSectionId: string): ChatModelNameDto => {
  const featureFlag = getGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.FLORENCE_CHAT_MODEL,
    courseSectionId
  );
  if (!featureFlag) {
    return ChatModelNameDto.claude;
  }
  if (featureFlag.featureValue === 'RANDOM') {
    const num = Math.random();
    if (num > 0.5) {
      return ChatModelNameDto.gpt35;
    }
    return ChatModelNameDto.claude;
  }
  return featureFlag.featureValue as ChatModelNameDto;
};

export const isShowFeedback = (entry: ChatEntryDto) => {
  return entry.author === ChatEntryAuthorDto.BOT
    && entry.id
    && ![TEMP_BOT_CHAT_ID_STREAM, TEMP_BOT_CHAT_ID_SYNC].includes(entry.id)
    && entry.entryType === ChatEntryTypeDto.MESSAGE;
};

export const getIsbnsFromCourseSection = (courseSection: CourseSectionDto): string[] => {
  if (!courseSection || !courseSection.entitlements || !courseSection.entitlements.length) {
    return [];
  }
  return courseSection.entitlements
    .map((entitlement) => {
      let evolveProduct: EvolveProductDto;
      try {
        evolveProduct = JSON.parse(entitlement.data) as EvolveProductDto;
      } catch (e) {
        evolveProduct = null as EvolveProductDto;
      }
      return evolveProduct;
    })
    .filter((evolveProduct: EvolveProductDto) => {
      if (!evolveProduct) {
        return false;
      }
      return ![
        EvolveProductTypeKey.SHERPATH_COMPONENT_NSS
      ].includes(evolveProduct.productTypeKey);
    })
    .map((evolveProduct) => {
      if (evolveProduct.productTypeKey === EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS && (evolveProduct.vbId || evolveProduct.vbID)) {
        return evolveProduct.vbId || evolveProduct.vbID;
      }
      return evolveProduct.isbn;
    });
};

export const getIsbnsFromAllCourseSections = (courseSections: CourseSectionDto[]): string[] => {
  if (!courseSections) {
    return [];
  }
  const isbns = courseSections.reduce((acc, courseSection) => {
    return [
      ...acc,
      ...getIsbnsFromCourseSection(courseSection)
    ];
  }, []);
  return uniq(isbns);
};

export const getSortedChats = (chats: ChatDto[]): ChatDto[] => {
  if (!chats) {
    return null;
  }
  return orderBy(chats, (chat) => {
    return chat.id;
  }, 'desc');
};
