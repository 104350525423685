import momentTimezone from 'moment-timezone';
import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  ChatDto,
  ChatEntryDto,
  ChatEntryEvaluationDto,
  ChatPaginationResponseDto,
  ChatPerformanceDto,
} from './florence-facade.dtos';

// GET /chats?page&pageSize?contextId?contextType

export const fetchChats = (): Promise<ChatPaginationResponseDto & {
  content: ChatDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/florence/chats', null));
  });
};

// DELETE /chats

export const deleteChats = (): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete('/florence/chats');
  });
};

// POST /chat

export const postChat = (chat: Omit<ChatDto, 'id'>): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/chat', { data: chat });
  });
};

// POST /chat

export const putChat = (chat: ChatDto): Promise<ChatDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(`/florence/chat/${chat.id}`, { data: chat });
  });
};

// GET /chat/{chatId}/entries

export const fetchChatEntries = (chatId: string): Promise<ChatPaginationResponseDto & {
  content: ChatEntryDto[];
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entries`);
  });
};

// POST /chat/{chatId}/entry

export const postChatEntry = (entry: Partial<Omit<ChatEntryDto, 'id'>>): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${entry.chatId}/entry`, { data: entry });
  });
};

export const postChatPrompt = (entry: Omit<ChatEntryDto, 'id'>): Promise<{
  entry: ChatEntryDto;
  botEntry: ChatEntryDto;
}> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${entry.chatId}/prompt`, { data: entry });
  });
};

// PUT /chat/{chatId}/entry/{entryId}

export const putChatEntry = (entry: ChatEntryDto): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${entry.chatId}/entry/${entry.id}`, { data: entry });
  });
};

// GET /chat/{chatId}/entry/{entryId}
export const fetchChatEntry = (chatId: number, entryId: number): Promise<ChatEntryDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/${entryId}`);
  });
};

// DELETE /chat/{chatId}/entry/{entryId}

export const deleteChatEntry = (chatId: string, entryId: string): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete(`/florence/chat/${chatId}/entry/${entryId}`);
  });
};

// Evaluation

// POST /chat/{chatId}/entry/{entryId}/evaluation
export const postChatEvaluation = (chatId: number, evaluation: Partial<ChatEntryEvaluationDto>): Promise<ChatEntryEvaluationDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/florence/chat/${chatId}/entry/${evaluation.entryId}/evaluation`, { data: evaluation });
  });
};

// PUT /chat/{chatId}/entry/{entryId}/evaluation/{evaluationId}
export const putChatEvaluation = (chatId: number, evaluation: ChatEntryEvaluationDto): Promise<ChatEntryEvaluationDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(`/florence/chat/${chatId}/entry/${evaluation.entryId}/evaluation/${evaluation.id}`, { data: evaluation });
  });
};

// POST /chat/{chatId}/entry/{entryId}/reference/{referenceId}/evaluation
// GET /chat/{chatId}/entry/{entryId}/evaluations
// GET /chat/{chatId}/entry/evaluations

// GET /chat/{chatId}/evaluations
export const fetchChatEvaluations = (chatId: string): Promise<ChatEntryEvaluationDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/florence/chat/${chatId}/entry/evaluations`);
  });
};

// GET /chat/{chatId}/entry/{entryId}/reference/{referenceId}/evaluations
// DELETE /chat/{chatId}/entry/{entryId}/evaluations
// DELETE /chat/{chatId}/entry/{entryId}/reference/{referenceId}/evaluations
// DELETE /chat/{chatId}/entry/{entryId}/evaluation/{evaluationId}
// DELETE /chat/{chatId}/entry/{entryId}/reference/{referenceId}/evaluation/{evaluationId}

// PUT /chat/{chatId}/entry/{entryId}/reference/{referenceId}/evaluation/{evaluationId}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchChatPerformance = (startDate: string, endDate: string, courseSectionIds: number[]): Promise<ChatPerformanceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/florence/student-engagement', {
      data: {
        startDate,
        endDate,
        timezone: momentTimezone.tz.guess(),
        courseSectionIds
      }
    });
  });
};
