import {
  withApiErrorLoggingOnly,
  eolsBaseApi
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  AssignmentDto,
  AssessmentSubmissionDto,
  AssessmentDto,
  AssignmentResultsDto
} from './eols-assessment-service.dtos';
import { EaqAssessmentConfig } from '../../pages/eaq-student-topic-report/eaq-student-topic-report.models';

export const fetchAssignments = (courseSectionId: string): Promise<AssignmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/v2/assignment', { courseSectionId }));
  });
};

// This API return full detail of an assignment
export const fetchAssignment = (assignmentId: string): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/v2/assignment/${assignmentId}`);
  });
};

export const fetchAssessmentByAssignmentIdAndUserId = (userId: string, assignmentId: number): Promise<AssessmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/v2/assessment', { userId, assignmentId }));
  });
};

export const fetchAssessmentsByAssignmentIdAndUserId = (userId: string, assignmentId: number): Promise<AssessmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/v2/assessment', { userId, assignmentId, filter: 'ALL' }));
  });
};

export const fetchAssignmentResults = (assignmentId: number): Promise<AssignmentResultsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/v2/assignment/${assignmentId}/results`);
  });
};

export const postSubmission = (assessmentSubmission: Partial<AssessmentSubmissionDto>): Promise<AssessmentSubmissionDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/v2/assessment/submission', {
      data: assessmentSubmission
    });
  });
};

export const submitAssessmentScore = (
  assessmentId: number,
  assessmentSubmission: Partial<AssessmentSubmissionDto>,
): Promise<AssessmentSubmissionDto> => {
  const submittedAssessmentSubmission: Partial<AssessmentSubmissionDto> = {
    ...assessmentSubmission,
    assessmentId,
  };
  return postSubmission(submittedAssessmentSubmission);
};

export const putAssignment = (assignment: AssignmentDto): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put('/v2/assignment', { data: assignment });
  });
};

export const createAuthessAssessment = (assessmentConfig: EaqAssessmentConfig) => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/eaq/assessment', { data: assessmentConfig });
  });
};
