/* eslint-disable sonarjs/cognitive-complexity */
import {
  uniq,
  uniqBy
} from 'lodash';
import { studentStudyActionType } from './studentStudy.actions';
import {
  studentStudyInitialState
} from './studentStudy.constants';
import {
  StudentStudyStore,
  ReduxPageWithFilter
} from './studentStudy.models';
import { getIsbnsForVantageCourse } from '../../utilities/app.utilities';

export const studentStudyReducer = (state = studentStudyInitialState, action): StudentStudyStore => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case studentStudyActionType.REQUEST_START:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount + 1,
      };
    case studentStudyActionType.REQUEST_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case studentStudyActionType.REQUEST_ERROR:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        errors: [action.payload, ...state.errors]
      };
    case studentStudyActionType.RESTORE_STATE:
      return {
        ...studentStudyInitialState,
        ...action.payload
      };
    case studentStudyActionType.SET_ISBNS:
      return {
        ...state,
        isbns: action.payload
      };
    case studentStudyActionType.SET_USER:
      return {
        ...state,
        courseSectionId: action.payload.courseSectionId ? action.payload.courseSectionId.toString() : null,
        roleId: action.payload.roleId,
        userId: action.payload.userId,
      };
    case studentStudyActionType.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        users: action.payload
      };
    case studentStudyActionType.FETCH_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: uniqBy([...action.payload.response, ...state.userHistory], 'id'),
        userHistoryStateCompletedRequests: uniq([...state.userHistoryStateCompletedRequests, action.payload.stateKey])
      };
    case studentStudyActionType.DELETE_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: studentStudyInitialState.userHistory,
        userHistoryStateCompletedRequests: studentStudyInitialState.userHistoryStateCompletedRequests,
        isCourseOwner: studentStudyInitialState.isCourseOwner
      };
    case studentStudyActionType.FETCH_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        groupActivity: action.payload
      };
    case studentStudyActionType.POST_USER_HISTORY_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userHistory: uniqBy([action.payload, ...state.userHistory], 'id')
      };
    case studentStudyActionType.SET_IS_COURSE_OWNER_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        isCourseOwner: action.payload.stateInfo,
        userCourseOwnerRecords: uniqBy([action.payload, ...state.userCourseOwnerRecords], 'id')
      };
    case studentStudyActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userCourseOwnerRecords: action.payload
      };
    case studentStudyActionType.FETCH_LINK_DATA_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        linkData: action.payload
      };
    case studentStudyActionType.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case studentStudyActionType.FETCH_GROUPED_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        featureFlagsGrouped: action.payload
      };
    case studentStudyActionType.FETCH_ASSIGNMENTS_SUCCESS: {
      const { assignments } = action.payload;
      return {
        ...state,
        assignments,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    }
    case studentStudyActionType.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        assessmentSubmissionsMap: {
          ...state.assessmentSubmissionsMap,
          [action.payload.assignmentId]: action.payload.assessmentSubmissions
        }
      };
    case studentStudyActionType.FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: action.payload.isTrackRequestCount ? state.pendingRequestCount - 1 : state.pendingRequestCount,
        assessmentSubmissionsMap: {
          ...state.assessmentSubmissionsMap,
          ...action.payload.assessmentSubmissionsMap
        }
      };
    case studentStudyActionType.FETCH_EOLS_USER_SUCCESS:
      return {
        ...state,
        eolsUser: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case studentStudyActionType.FETCH_COURSE_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        currentCourse: action.payload,
        vantageIsbns: getIsbnsForVantageCourse(state.isbns, action.payload)
      };
    case studentStudyActionType.FETCH_EVOLVE_USER_SUCCESS:
      return {
        ...state,
        evolveUser: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case studentStudyActionType.FETCH_CROSSWALK_USERS_SUCCESS:
      return {
        ...state,
        crosswalkUsers: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case studentStudyActionType.SET_ASSESSMENT_START_TIME_MAP:
      return {
        ...state,
        assessmentStartTimeMap: {
          ...state.assessmentStartTimeMap,
          [action.payload.assessmentId]: action.payload.startTime,
        },
      };
    case studentStudyActionType.SET_APP_LINK_COOKIES:
      return {
        ...state,
        appLinkCookies: {
          ...action.payload
        },
      };
    case studentStudyActionType.RESET_STORE:
      return {
        ...studentStudyInitialState,
        messages: state.messages
      };
    case studentStudyActionType.SET_HESI_FOCUS_CHAPTER_FILTER: {
      const { props, reduxPage } = action.payload as { props: Array<string>; reduxPage: ReduxPageWithFilter };
      const updatedState: Partial<StudentStudyStore> = {
        [reduxPage]: {
          ...state[reduxPage],
          filterState: {
            ...state[reduxPage].filterState,
            isHesiFocusChapterFilterEnabled: props
          }
        },
      };
      return {
        ...state,
        ...updatedState
      };
    }
    case studentStudyActionType.FETCH_USER_ENGAGEMENT_REPORT_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        userEngagementReport: action.payload,
      };
    case studentStudyActionType.FETCH_MODULE_SEQUENCE_SUCCESS:
      return {
        ...state,
        moduleSequenceMap: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case studentStudyActionType.SET_STORE_PROPS:
      return {
        ...state,
        ...action.payload
      };
    case studentStudyActionType.SET_ENABLE_DEEP_LINK:
      return {
        ...state,
        enableDeepLink: action.payload
      };
    case studentStudyActionType.FETCH_MIGRATED_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        migratedEntitlements: action.payload
      };
    case studentStudyActionType.FETCH_AB_TEST_FLAVOR_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        abTestFlavors: [
          ...state.abTestFlavors,
          action.payload
        ]
      };
    case studentStudyActionType.RESET_STATE_ON_LAUNCH:
      return {
        ...studentStudyInitialState,
        // Window.sessionStorage follows the course switcher new tab so need to clear data
        // These state props are set in app component or app link component
        // before the reset so want to preserve them
        appLinkCookies: state.appLinkCookies,
        isbns: state.isbns,
        courseSectionId: state.courseSectionId,
        roleId: state.roleId,
        userId: state.userId,
        messages: state.messages,
      };
    case studentStudyActionType.FETCH_STUDENT_STUDY_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        pendingRequestCount: state.pendingRequestCount - 1,
        studentStudyDto: action.payload
      };
    case studentStudyActionType.FETCH_OSMOSIS_TOKEN_SUCCESS:
      return {
        ...state,
        osmosisTokenDto: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    case studentStudyActionType.FETCH_ALL_USER_COURSE_SECTIONS_SUCCESS:
      return {
        ...state,
        allUserCourseSections: action.payload,
        pendingRequestCount: state.pendingRequestCount - 1,
      };
    default:
      return state;
  }
};
