/* eslint-disable react/react-in-jsx-scope */
import { lazy } from 'react';
import { RoutePath } from './app.constants';
import { ELSCommonUIConstants } from '../els.components';

export const AppRoutes = {
  getRoutes: () => [
    {
      path: RoutePath.LOGIN,
      component: lazy(() => import(/* webpackChunkName: "Login.page" */ '../../pages/login/Login.page')),
      isPrivate: false,
      exact: false,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.login,
          pageName: 'ssa:login',
        }
      }
    },
    {
      path: RoutePath.CW_LOGOUT,
      component: lazy(() => import(/* webpackChunkName: "CoursewareLogout.page" */ '../../pages/logout/CoursewareLogout.page')),
      isPrivate: false,
      exact: false,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:logout',
        }
      }
    },
    {
      path: RoutePath.APP_LINK_REDIRECT,
      component: lazy(() => import(/* webpackChunkName: "AppLinkRedirect.page" */ '../../pages/app-link-redirect/AppLinkRedirect.page')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:inter-app-redirect',
        }
      }
    }, {
      path: RoutePath.EAQ_STUDENT_STUDY,
      component: lazy(() => import(/* webpackChunkName: "EaqStudentStudy.page" */ '../../pages/eaq-student-study/EaqStudentStudy.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:eaq-student-study',
        }
      }
    }, {
      path: RoutePath.EAQ_STUDENT_TOPIC_REPORT,
      component: lazy(() => import(/* webpackChunkName: "EaqStudentTopicReport.page" */ '../../pages/eaq-student-topic-report/EaqStudentTopicReport.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:eaq-student-topic-report',
        }
      }
    }, {
      path: RoutePath.SUPPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "Support.page" */ '../../pages/support/Support.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ssa:support',
        }
      }
    },
    {
      path: RoutePath.AI_CHAT,
      component: lazy(() => import(/* webpackChunkName: "AiChat.page" */ '../../pages/ai-chat/AiChat.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:ai-chat',
        }
      }
    },
    {
      path: RoutePath.ASSESSMENT_START,
      component: lazy(() => import(/* webpackChunkName: "EaqSelfStudyAssignmentEditor.page" */ '../../pages/eaq-self-study-assessment-editor/EaqSelfStudyAssessmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:assessment-start',
        }
      }
    },
    {
      path: RoutePath.AI_CHAT_PERFORMANCE,
      component: lazy(() => import(/* webpackChunkName: "AiChatPerformance.page" */ '../../pages/ai-chat-performance/AiChatPerformance.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:ai-chat-performance',
        }
      }
    },
  ]
};
