import {
  StudentStudyStore
} from './studentStudy.models';

export const studentStudyInitialState: StudentStudyStore = {
  userEngagementReport: null,
  userHistory: [],
  userHistoryStateCompletedRequests: [],
  courseSectionId: null,
  currentCourse: null,
  allUserCourseSections: null,
  errors: [],
  evolveUser: null,
  featureFlagsGrouped: null,
  isbns: null,
  vantageIsbns: null,
  isCourseOwner: false,
  linkData: null,
  messages: {},
  pendingRequestCount: 0,
  roleId: '',
  userCourseOwnerRecords: [],
  userId: null,
  users: [],
  eolsUser: null,
  taxonomies: [],
  assignments: [],
  assessmentSubmissionsMap: null,
  externalEntities: [],
  crosswalkUsers: [],
  assessmentStartTimeMap: {},
  appLinkCookies: {
    token: null,
    linkId: null
  },
  collapsedFolderIds: [],
  moduleSequenceMap: null,
  assessments: [],
  enableDeepLink: false,
  groupActivity: null,
  migratedEntitlements: null,
  registeredToken: null,
  abTestFlavors: [],
  studentStudyDto: [],
  osmosisTokenDto: null
};

export const UNKNOWN = 'UNKNOWN';
export const EAQ_SELF_STUDY = 'EAQ_SELF_STUDY';
