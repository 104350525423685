export enum ChatContextTypeDto {
  COURSE_SECTION_ID = 'COURSE_SECTION_ID',
  EVAL = 'EVAL',
  ASSIGNMENT_ID = 'ASSIGNMENT_ID'
}

export enum ChatModelNameDto {
  gpt4 = 'gpt4',
  gpt35 = 'gpt35',
  titan = 'titan', // AWS Titan TG1 Large
  claude = 'claude', // Claude 2
}

export enum ChatTypeDto {
  FLORENCE_USER_CHAT = 'FLORENCE_USER_CHAT',
  BATCH_EVAL_CHAT = 'BATCH_EVAL_CHAT',
}

export enum ChatAttributes {
  isTestUser = 'isTestUser',
  isMasqueradeUser = 'isMasqueradeUser',
  isDirectAccess = 'isDirectAccess',
  institutionNormalized = 'institutionNormalized',
  userRole = 'userRole',
}

export type ChatDto = {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  userId: number;
  contextId: string;
  contextType: ChatContextTypeDto;
  status: ChatStatusDto;
  entries: ChatEntryDto[];
  type: ChatTypeDto;
  modelName: ChatModelNameDto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<ChatAttributes, any>;
}

export enum ChatStatusDto {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum ChatEntryAuthorDto {
  USER = 'USER',
  BOT = 'BOT'
}

export enum ChatEntryTypeDto {
  EXPLAINER = 'EXPLAINER',
  MESSAGE = 'MESSAGE',
}

export enum ChatQueryIntentDto {
  COMMENT = 'COMMENT',
  QUESTION = 'QUESTION',
  TEST = 'TEST',
}

export enum SearchParam {
  RESOURCE_TYPE = 'resource_type', // ChatResourceTypeDto
  REFERENCE_CLASS = 'reference_class', // ChatReferenceClassDto
  RESOURCE_URI = 'resource_uri', // string
  RESOURCE_NAME = 'resource_name', // string
  LOCATIONS = 'locations', // list[Location] = None
  CONTENT_TEXT = 'content_text', // string
  ISBN = 'isbn', // string
}

type SearchFilter = Partial<{
  [key in SearchParam]: string | SearchFilter | SearchFilter[] | undefined;
}> & {
  AND?: SearchFilter[];
  OR?: SearchFilter[];
};

export type ChatEntryTraceDto = {
  inputTokenCount: number;
  outputTokenCount: number;
  inputText: string;
  outputText: string;
  responseTime: number;
  streamingTime: number;
  version: string;
}

export enum ChatEntryTraceKey {
  STUDENT_STUDY_APP = 'STUDENT_STUDY_APP',
  CONTEXT_RESOLUTION = 'CONTEXT_RESOLUTION',
  INTENT_CLASSIFICATION = 'INTENT_CLASSIFICATION',
  SEARCH = 'SEARCH',
  FINAL_RESPONSE = 'FINAL_RESPONSE',
}

export type ChatEntryDto = {
  id: number; // db id
  chatId: number;
  parentEntryId: number; // id of the entry this is responding to - null if first entry
  index: number; // order in chat
  createdAt: string;
  updatedAt: string;
  message: string;
  author: ChatEntryAuthorDto; // bot or user
  entryType: ChatEntryTypeDto; // message or maybe something like quiz in the future
  references: Partial<ChatReferenceDto>[];
  queryIntent: ChatQueryIntentDto;
  searchFilter: SearchFilter;
  traces: Partial<Record<ChatEntryTraceKey, ChatEntryTraceDto>>; // Replacement for systemVersions
  evalPromptId: string;
  totalOutputTokenCount: number;
  totalInputTokenCount: number;
  totalTime: number;
}

export type ChatEntryEvaluationDto = {
  id: number; // db id
  userId: string;
  userType: EvaluationUserTypeDto;
  entryId: number;
  comment: string;
  overall: number;
  comprehension: number;
  correctness: number;
  completeness: number;
  harmfulness: number;
  accuracy: string;
  coherency: string;
  organization: string;
  relevancy: string;
  detail: string;
  entryFeedbackReferences: Partial<ChatReferenceEvaluationDto>[];
}

export type ChatReferenceEvaluationDto = {
  id: number; // db id
  chatEntryEvalId: number; // db id of ChatEntryEvaluationDto
  referenceId: number;
  overall: number;
}

export enum EvaluationUserTypeDto {
  CLIENT = 'CLIENT',
  EXPERT = 'EXPERT',
}

export enum ChatReferenceClassDto {
  CITATION = 'CITATION',
  RECOMMENDATION = 'RECOMMENDATION'
}

type ChatReferencePartDto = {
  uri: string;
  index: number;
  type: ChatReferenceTypeDto;
}

export type ChatReferenceDto = {
  id: number; // database id
  entryId: number;
  type: ChatReferenceTypeDto;
  referenceClass: ChatReferenceClassDto;
  title: string;
  content: string;
  parts?: ChatReferencePartDto[]; // this is a proposal so do not use this yet
  uri: string; // chapter/15/section/4/
  index: number;
  isVisible: boolean;
  confidenceScore: number;
  resourceType: ChatResourceTypeDto;
  resourceUri: string; // book/name_of_book/
  resourceTitle: string;
}

export enum ChatReferenceTypeDto {
  TEXT = 'TEXT',
}

export enum ChatResourceTypeDto {
  EBOOK = 'EBOOK',
  CASE_STUDY = 'CASE_STUDY',
  LESSON = 'LESSON',
  VIDEO = 'VIDEO',
}

export type ChatPaginationResponseDto = {
  currentPage: number;
  last: boolean;
  totalItems: number;
  totalPages: number;
}

export type ChatPerformanceDto = {
  studentCount: number;
  queryCount: number;
  perDayMetrics: {
    date: string;
    studentCount: number;
    queryCount: number;
  }[];
}
